import CustomNode from "./custom-node";

const customNodeConfig = {
	automaticRearrangeAfterDropNode: false,
	collapsible: false,
	directed: false,
	height: 700,
	highlightDegree: 1,
	highlightOpacity: 0.2,
	linkHighlightBehavior: true,
	initialZoom: 1,
	maxZoom: 8,
	minZoom: 0.1,
	nodeHighlightBehavior: true,
	panAndZoom: false,
	staticGraph: false,
	width: 960,
	d3: {
		alphaTarget: 0.05,
		gravity: -800,
		linkLength: 400,
		linkStrength: 2,
		disableLinkForce: false
	},
	node: {
		color: "#d3d3d3",
		fontColor: "black",
		fontSize: 12,
		fontWeight: "normal",
		highlightColor: "red",
		highlightFontSize: 12,
		highlightFontWeight: "bold",
		highlightStrokeColor: "SAME",
		highlightStrokeWidth: 1.5,
		labelProperty: "name",
		mouseCursor: "pointer",
		opacity: 1,
		renderLabel: false,
		size: {
			width: 3000,
			height: 900
		},
		strokeColor: "none",
		strokeWidth: 1.5,
		svg: "",
		symbolType: "square",
		viewGenerator: (node) => (<CustomNode node={node} />)// eslint-disable-line react/display-name
	},
	link: {
		fontSize: 12,
		fontWeight: "bold",
		highlightColor: "red",
		highlightFontSize: 12,
		renderLabel: true,
		highlightFontWeight: "bold",
		highlightStrokeWidth: 2,
		semanticStrokeWidth: true,
		strokeWidth: 1.5,
		markerHeight: 6,
		markerWidth: 6,
		strokeLinecap: "butt"
	}
};

export default customNodeConfig;
