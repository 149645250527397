import { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import {
	CircularProgress,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	DialogContentText,
	Snackbar
} from "@mui/material";
// services
import Gupport from "../../services/gupport";

class DeviceInfoDialog extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			showCopySnackbar: false,
			deviceExtraData: null,
			error: null
		};

		this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
	}

	componentDidMount() {
		if (this.props.open && this.props.isThirdParty) {
			this.fetchNativeJson();
		}
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.open && this.props.open && this.props.isThirdParty) {
			this.fetchNativeJson();
		}
	}

	handleCopyClick(data) {
		const json = JSON.stringify(data, null, 2);
		navigator.clipboard.writeText(json).then(() => {
			this.setState({
				showCopySnackbar: true
			});
		}, (error) => {
			console.warn(error);
		});
	}

	handleSnackbarClose() {
		this.setState({
			showCopySnackbar: false
		});
	}

	fetchNativeJson() {
		this.setState({
			loading: true
		});

		const cmd = {
			action: "gatewayAction",
			module: "gateway",
			function: "get_device_extra",
			params: [{
				id: this.props.device.id
			}],
			gatewayId: this.props.gatewayId
		};
		Gupport.send(cmd, (error, msg) => {
			if (error || msg.payload.status === "error") {
				this.setState({
					loading: false,
					error: error,
					deviceExtraData: null
				});
			} else {
				this.setState({
					loading: false,
					error: null,
					deviceExtraData: msg.payload.data
				});
			}
		});
	}

	render() {
		const { t } = this.props;
		const data = this.props.isThirdParty ? this.state.deviceExtraData : this.props.device;

		return (
			<>
				<Dialog
					fullWidth={true}
					maxWidth="md"
					open={this.props.open}
					onClose={this.props.onClose}
				>
					<DialogTitle>{t("gateway.deviceInfoDialogTitle")}</DialogTitle>
					<DialogContent>
						{this.state.loading
							? <CircularProgress style={{ margin: "auto", display: "block" }} />
							: this.state.error ? <DialogContentText>{t("gateway.errorMsg")}</DialogContentText> :
								<>
									<Button variant="contained" onClick={() => (this.handleCopyClick(data))}>
										{t("gateway.copy")}
									</Button>
									<pre>{JSON.stringify(data, null, 2)}</pre>
								</>
						}
					</DialogContent>
					<DialogActions>
						<Button color="inherit" onClick={this.props.onClose}>{t("dialog.close")}</Button>
					</DialogActions>
				</Dialog>
				<Snackbar
					open={this.state.showCopySnackbar}
					message={t("gateway.copyMsg")}
					autoHideDuration={3000}
					onClose={this.handleSnackbarClose}
				/>
			</>
		);
	}

}

DeviceInfoDialog.defaultProps = {
	device: null
};

DeviceInfoDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	isThirdParty: PropTypes.bool.isRequired,
	device: PropTypes.object,
	gatewayId: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired,
};

export default withTranslation()(DeviceInfoDialog);
