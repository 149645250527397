import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Table,
	TableBody,
	TableRow,
	TableCell
} from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
// types
import type { DeviceData } from "../../types/device";

type Props = {
	open: boolean;
	device: DeviceData | null;
	onClose: () => void;
};

const defaultStyle = {
	whiteSpace: "normal",
	overflow: "inherit",
	wordBreak: "break-word"
};
const attColumnStyle = {
	...defaultStyle,
	height: "auto"
};
const keyAttColumnStyle = {
	...attColumnStyle,
	fontWeight: "bold",
	width: "auto"
};

const DeviceAttributesInfoDialog = (props: Props) => {
	const { t } = useTranslation();

	return (
		<Dialog
			open={props.open}
			fullWidth={true}
			onClose={props.onClose}
		>
			<DialogTitle>{t("gateway.deviceAttributesInfoDialogTitle")}</DialogTitle>
			<DialogContent>
				<Table>
					<TableBody>
						{props.device && Object.keys(props.device.attributes).map((key) => (
							<TableRow key={key} className="last-row-no-border" style={{ height: "30px" }}>
								<TableCell sx={attColumnStyle}>{key}</TableCell>
								<TableCell sx={keyAttColumnStyle}>{props.device?.attributes[key]}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose}>{t("dialog.close")}</Button>
			</DialogActions>
		</Dialog>
	);
};

DeviceAttributesInfoDialog.defaultProps = {
	device: null
};

DeviceAttributesInfoDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	device: PropTypes.object,
	onClose: PropTypes.func.isRequired
};

export default DeviceAttributesInfoDialog;
