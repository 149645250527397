import { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
// cmp
import Na from "../na";
// services
import Gupport from "../../services/gupport";
import { formatBytes } from "../../services/utils";
import { muiTheme } from "@local/theme";

class GatewaySubscribers extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			error: null,
			subs: []
		};
	}

	componentDidMount() {
		this.fetchSubscribers(this.props.gatewayId);
	}

	fetchSubscribers(gatewayId) {
		if (Gupport.ready) {
			this.getSubscribers(gatewayId);
		} else {
			Gupport.once("ready", () => {
				this.getSubscribers(gatewayId);
			});
		}
	}

	getSubscribers(gatewayId) {
		const { t } = this.props;
		if (Gupport.getSubscribers) {
			Gupport.getSubscribers({
				gatewayId: gatewayId
			}, (error, msg) => {
				if (error || msg.payload.status === "error") {
					this.setState({
						loading: false,
						error: msg.payload.data,
						subs: []
					});
				} else {
					this.setState({
						loading: false,
						error: null,
						subs: msg.payload.data
					});
				}
			});
		} else {
			this.setState({
				loading: false,
				error: t("users.lError"),
				subs: []
			});
		}
	}

	render() {
		const { t } = this.props;
		if (this.state.error) {
			return <div>{this.state.error}</div>;
		}

		const columns = [
			{
				field: "username",
				headerName: t("gateway.sUsername"),
				flex: 1,
				renderCell: (params) => (
					<a href={`user_desc.html#/users/${params.row.user_id}/general`} target="_blank" rel="noopener noreferrer" style={{color: muiTheme.palette.primary.main, textDecoration: "none"}}>
						{params.value}
					</a>
				)
			},
			{
				field: "connected",
				headerName: t("gateway.sconnected"),
				flex: 1,
				renderCell: (params) => (<time dateTime={new Date(params.value).toISOString()}>{new Date(params.value).toLocaleString()}</time>)
			},
			{
				field: "agent",
				headerName: t("gateway.sagent"),
				flex: 3
			},
			{
				field: "txCount",
				headerName: t("gateway.txCount"),
				flex: 1
			},
			{
				field: "txSize",
				headerName: t("gateway.txSize"),
				flex: 1,
				renderCell: (params) => (formatBytes(params.value) || <Na />)
			},
			{
				field: "rxCount",
				headerName: t("gateway.rxCount"),
				flex: 1
			},
			{
				field: "rxSize",
				headerName: t("gateway.rxSize"),
				flex: 1,
				renderCell: (params) => (formatBytes(params.value) || <Na />)
			}
		];

		return (
			<Paper>
				<DataGrid
					loading={this.state.loading}
					columns={columns}
					rows={this.state.subs}
				/>
			</Paper>
		);
	}

}

GatewaySubscribers.propTypes = {
	gatewayId: PropTypes.string.isRequired,
	t: PropTypes.func.isRequired
};

export default withTranslation()(GatewaySubscribers);
