import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";

type Props = {
	loading?: boolean;
	error?: {
		message: string;
	};
	payload?: object;
};

const ActionResult = (props: Props) => {
	const { t } = useTranslation();
	if (props.loading) {
		return <CircularProgress style={{margin: "auto", display: "block"}} />;
	}
	if (props.error) {
		return <div>{t("generic.errorWithMsg", { msg: ""})}<pre>{props.error.message}</pre></div>;
	}
	return <pre>{JSON.stringify(props.payload, null, 2)}</pre>;
};

ActionResult.defaultProps = {
	loading: false,
	error: undefined,
	payload: undefined
};

ActionResult.propTypes = {
	loading: PropTypes.bool,
	error: PropTypes.shape({
		message: PropTypes.string
	}),
	payload: PropTypes.object
};

export default ActionResult;
