import PropTypes from "prop-types";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
// cmp
import Na from "../na";
// services
import type { GridColDef } from "@mui/x-data-grid";

const UPDATE_STATUS = {
	update: "UPDATED",
	noupdate: "NO UPDATE AVAILABLE",
	error: "ERROR"
} as const;

interface FirmwareInfo {
	name: string;
	version: string;
	status: string;
}

type Props = {
	updatedFirmwareInfo: Array<FirmwareInfo>;
};

const UpdatedFirmwareInfo = (props: Props) => {
	const { t } = useTranslation();

	const columns: Array<GridColDef<FirmwareInfo>> = [
		{
			field: "name",
			headerName: t("gateway.plugin"),
			flex: 1
		},
		{
			field: "status",
			headerName: t("gateway.updatedStatus"),
			flex: 1,
			renderCell: (params) => (UPDATE_STATUS[params.value] || <Na />)
		},
		{
			field: "version",
			headerName: t("gateway.currentVersion"),
			flex: 1,
			renderCell: (params) => (params.value || <Na />)
		}
	];

	return (
		<DataGrid
			columns={columns}
			rows={props.updatedFirmwareInfo}
			getRowId={(row) => (row.name)}
		/>
	);
};

UpdatedFirmwareInfo.defaultProps = {
	updatedFirmwareInfo: []
};

UpdatedFirmwareInfo.propTypes = {
	updatedFirmwareInfo: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			version: PropTypes.string.isRequired,
			status: PropTypes.string.isRequired
		})
	)
};

export default UpdatedFirmwareInfo;
