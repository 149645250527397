import { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { CircularProgress, Paper } from "@mui/material";
import Terminal from "../terminal/terminal";
// services
import CC from "../../services/cc";
import Gupport from "../../services/gupport";

class GatewayTerminal extends Component {

	constructor(props) {
		super(props);

		this.cc = new CC({heartbeat: true});

		this.state = {
			connected: this.cc.connected,
			connecting: this.cc.connecting,
			disconnecting: this.cc.disconnecting,
			terminalEntries: [],
		};

		this.handleConnected = this.handleConnected.bind(this);
		this.handleDisconnected = this.handleDisconnected.bind(this);
		this.handleConnecting = this.handleConnecting.bind(this);
		this.handleDisconnecting = this.handleDisconnecting.bind(this);
		this.handleCCMessage = this.handleCCMessage.bind(this);
		this.handleSendCmd = this.handleSendCmd.bind(this);
		this.handleLoadFile = this.handleLoadFile.bind(this);
		this.handleClearEntries = this.handleClearEntries.bind(this);
	}

	componentDidMount() {
		this.cc.on("connected", this.handleConnected);
		this.cc.on("disconnected", this.handleDisconnected);
		this.cc.on("connecting", this.handleConnecting);
		this.cc.on("disconnecting", this.handleDisconnecting);

		this.connect();
	}

	componentWillUnmount() {
		this.cc.off("connected", this.handleConnected);
		this.cc.off("disconnected", this.handleDisconnected);
		this.cc.off("connecting", this.handleConnecting);
		this.cc.off("disconnecting", this.handleDisconnecting);

		this.disconnect();
	}

	handleConnected() {
		this.setState({
			connected: true,
			connecting: false,
			disconnecting: false,
		});
	}

	handleDisconnected() {
		this.setState({
			connected: false,
			connecting: false,
			disconnecting: false,
		});
	}

	handleConnecting() {
		this.setState({
			connecting: true,
			disconnecting: false,
		});
	}

	handleDisconnecting() {
		this.setState({
			connecting: false,
			disconnecting: true,
		});
	}

	connect() {
		if (this.cc.ready) {
			this.onReady();
		} else {
			this.cc.once("ready", () => {
				this.onReady();
			});
		}
	}

	onReady() {
		if (this.cc.disconnecting) {
			this.cc.once("disconnected", () => {
				this.doConnect();
			});
		} else {
			this.doConnect();
		}
	}

	doConnect() {
		if (!this.cc.connecting) {
			if (this.cc.gatewayId !== this.props.gatewayId) {
				this.cc.setGateway(this.props.gatewayId);
			}
			this.cc.on("message", this.handleCCMessage);
			this.cc.connect();
		}
	}

	disconnect(reconnect = false) {
		if (this.state.connected) {
			if (!this.cc.disconnecting) {
				if (reconnect) { // Workaround for tab unmount/mount problem
					this.cc.once("disconnected", () => {
						this.doConnect();
					});
				}
				this.cc.off("message", this.handleCCMessage);
				this.cc.disconnect();
			}
		}
	}

	handleCCMessage(msg) {
		if (msg.payload && msg.payload.data && (msg.payload.action === "rtrace" || msg.payload.action === "jsonTrace") &&
			((this.props.module === "shell" && msg.payload.module === "shell") || (this.props.module === "ccc" && msg.payload.module !== "shell"))) {
			const entry = {
				type: msg.payload.action,
				data: msg.payload.data,
				ts: msg.payload.ts || new Date().toISOString(),
				dir: GatewayTerminal.getDir(msg.payload),
			};
			this.setState((prevState) => ({
				terminalEntries: [...prevState.terminalEntries, entry],
			}));
		}
	}

	handleLoadFile(file) {
		const fileReader = new FileReader();
		fileReader.readAsText(file, "UTF-8");
		fileReader.onload = (event) => {
			try {
				const terminalEntries = JSON.parse(event.target?.result);
				this.setState((prevState) => ({
					terminalEntries: [...prevState.terminalEntries, ...terminalEntries],
				}));
			} catch (error) {
				console.info("Error converting object to string");
			}
		};
	}

	static getDir(payload) {
		if (payload.dir) {
			return payload.dir;
		}
		if (payload.data.action) {
			return "tx";
		}
		if (payload.data.info) {
			return "rx";
		}
		return null;
	}

	handleSendCmd(cmd) {
		this.setState((prevState) => ({
			terminalEntries: [
				...prevState.terminalEntries,
				{
					type: "cmd",
					data: cmd,
					ts: new Date().toISOString(),
					dir: "tx",
				},
			],
		}));
		this.cc.cc({
			module: this.props.module,
			command: (this.props.module === "shell") ? `:${cmd}` : cmd,
		});
	}

	handleClearEntries() {
		this.setState({
			terminalEntries: [],
		});
	}

	render() {
		const { t } = this.props;

		if (this.state.disconnecting) {
			return <CircularProgress />;
		}
		if (!this.state.connecting && !this.state.connected) {
			return <span>{t("generic.disconnected")}</span>;
		}

		return (
			<Paper>
				<Terminal
					cc={this.cc}
					module={this.props.module}
					readOnly={!Gupport.hasLevel("ccc_write")}
					gatewayId={this.props.gatewayId}
					terminalEntries={this.state.terminalEntries}
					onLoadFile={this.handleLoadFile}
					onSendCmd={this.handleSendCmd}
					onClear={this.handleClearEntries}
				/>
			</Paper>
		);
	}

}

GatewayTerminal.propTypes = {
	gatewayId: PropTypes.string.isRequired,
	module: PropTypes.oneOf(["ccc", "shell"]).isRequired,
	t: PropTypes.func.isRequired,
};

export default withTranslation()(GatewayTerminal);
