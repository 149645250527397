import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
	Paper
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
// cmp
import RowEntry from "../row-entry";

const UpdateFirmwareInfo = (props) => {
	const { t } = useTranslation();
	const getPreviousVersion = (name) => {
		const patch = props.firmware.config.find((item) => (item.name === name));
		return patch.version || "";
	};

	const isCurrentVersionAvailable = props.firmware.running && props.firmware.running.length > 0;

	const columns = [
		{
			field: "plugin",
			headerName: t("gateway.plugin"),
			flex: 1
		},
		{
			field: "lastUpdated",
			headerName: t("gateway.lastUpdated"),
			flex: 1
		},
		{
			field: "currentVersion",
			headerName: t("gateway.currentVersion"),
			flex: 3
		},
		{
			field: "configuredVersion",
			headerName: t("gateway.configuredVersion"),
			flex: 1
		}
	];

	const runningRowMap = (patch) => ({
		id: patch.name,
		plugin: patch.name,
		lastUpdated: new Date(patch.last_update || null).toLocaleString() ?? t("messages.na"),
		currentVersion: patch.version ?? t("messages.na"),
		configuredVersion: getPreviousVersion(patch.name) ?? t("messages.na")
	});

	const configRowMap = (patch) => ({
		id: patch.name,
		plugin: patch.name,
		lastUpdated: t("messages.na"),
		currentVersion: t("messages.na"),
		configuredVersion: patch.version ?? t("messages.na")
	});

	return (
		<>
			{props.firmware.group &&
				<Paper style={{ margin: "16px" }} elevation={2}>
					<RowEntry title={t("gateway.gatewayGroup")}>
						{props.firmware.group}
					</RowEntry>
				</Paper>
			}
			<DataGrid
				columns={columns}
				rows={isCurrentVersionAvailable ? props.firmware.running.map(runningRowMap) : props.firmware.config.map(configRowMap)}
			/>
		</>
	);
};

UpdateFirmwareInfo.propTypes = {
	firmware: PropTypes.shape({
		running: PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string.isRequired,
				version: PropTypes.string.isRequired,
				last_update: PropTypes.string
			})
		).isRequired,
		config: PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string.isRequired,
				version: PropTypes.string
			})
		).isRequired,
		group: PropTypes.string.isRequired
	}).isRequired
};

export default UpdateFirmwareInfo;
