import PropTypes from "prop-types";
import {
	ArcElement,
	DoughnutController,
	Legend,
	Title,
	Tooltip
} from "chart.js";
import "chartjs-adapter-date-fns";
import { ReactChart } from "chartjs-react";
// types
import type { ChartData, ChartOptions } from "chart.js";

type Props = {
	data: ChartData<"doughnut">;
	options: ChartOptions<"doughnut">;
};

ReactChart.register(
	Legend,
	ArcElement,
	Title,
	DoughnutController,
	Tooltip
);

const DoughnutChart = (props: Props) => (
	<ReactChart
		id="doughnut-chart"
		type="doughnut"
		data={props.data}
		options={props.options}
	/>
);

DoughnutChart.propTypes = {
	data: PropTypes.object.isRequired,
	options: PropTypes.object.isRequired
};

export default DoughnutChart;
