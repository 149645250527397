import PropTypes from "prop-types";
import { Card, CardHeader, IconButton } from "@mui/material";
// services
import { icons } from "@local/theme";

interface GraphNode {
	id: string;
	name: string;
	isGateway: boolean;
	isListedDevice: boolean;
	icon: string;
	copyMacId: (nodeId: string) => void;
}

type Props = {
	node: GraphNode;
};

const CustomNode = (props: Props) => (
	<Card style={{ backgroundColor: props.node.isGateway ? "#008fff" : (props.node.isListedDevice ? "#8fbc8f" : "#808080") }}>
		<CardHeader
			className="CardHeader-width-fix"
			avatar={
				<img src={props.node.icon} style={{ height: "40px", width: "40px" }} />
			}
			title={props.node.name}
			subheader={props.node.id}
			titleTypographyProps={{ noWrap: true, variant: "body2", style: { maxWidth: "140px", marginRight: "5px", fontWeight: "bold", color: "rgba(0, 0, 0, 0.870588)" } }}
			subheaderTypographyProps={{ noWrap: true, variant: "body2", style: { color: "rgba(0, 0, 0, 0.870588)" } }}
			action={
				<IconButton onClick={() => props.node.copyMacId(props.node.id)} style={{ marginTop: "5px" }}>
					<icons.CopyContent htmlColor="rgba(0, 0, 0, 0.870588)" />
				</IconButton>
			}
		/>
	</Card>
);

CustomNode.propTypes = {
	node: PropTypes.shape({
		id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		isGateway: PropTypes.bool.isRequired,
		isListedDevice: PropTypes.bool.isRequired,
		icon: PropTypes.string.isRequired,
		copyMacId: PropTypes.func.isRequired
	}).isRequired
};

export default CustomNode;
