import { Component } from "react";
import PropTypes from "prop-types";
import { Trans, withTranslation } from "react-i18next";
import reactStringReplace from "react-string-replace";
import {
	Paper,
	CircularProgress,
	Button,
	MenuItem,
	ListItemText,
	Select,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Divider,
	TextField,
	Snackbar,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { intervalToDuration } from "date-fns";
//cmp
import Na from "../na";
import RowEntry from "../row-entry";
import UpdateFirmwareInfo from "./update-firmware-info";
import UpdatedFirmwareInfo from "./updated-firmware-info";
import ActionResult from "../playground/action-result";
// services
import Gupport from "../../services/gupport";
import Settings from "../../services/settings";
import { merge, /*formatBytes, */sortAlphabetically, BBCODE_BOLD_REGEX } from "../../services/utils";

class GatewayAdvancedInfo extends Component {

	constructor(props) {
		super(props);

		this.state = {
			// ready: Gupport.ready,
			loading: true,
			searchError: null,
			gateway: null,
			// loaded: false,
			buttonDisable: false,
			hostButton: false,
			openDialog: false,
			rebootDialog: false,
			rebootButtonDisabled: false,
			channelValues: [],
			hostValues: [],
			// error: {},
			// updatingGagent: false,
			// updatingGagentLib: false,
			// disableUpdateGagent2Lib: false,
			// disableUpdateGagent2: false,
			channelEditMode: false,
			hostEditMode: false,
			checkingFirmwareVersions: false,
			showUpdateBtn: false,
			showFirmwareCheckErrorDialog: false,
			isUpdateFailed: false,
			isFirmwareUpdateCompleted: false,
			updatingFirmware: false,
			updatedFirmwareInfo: null,
			showUpdateFirmwareDialog: false,
			showConfirmUpdateDialog: false,
			firmware: null,
			value: undefined,
			actionLoading: false,
			actionResultPayload: undefined,
			actionResultError: undefined,
			showCopySnackbar: false,
		};

		this.handleGupportReady = this.handleGupportReady.bind(this);
		this.handleGupportMessage = this.handleGupportMessage.bind(this);
		this.handleSettingsLoaded = this.handleSettingsLoaded.bind(this);
		this.fetchGateway = this.fetchGateway.bind(this);
		this.getGateway = this.getGateway.bind(this);
		this.fetchChannels = this.fetchChannels.bind(this);
		this.getChannels = this.getChannels.bind(this);
		this.handleSelectChannel = this.handleSelectChannel.bind(this);
		this.setChannel = this.setChannel.bind(this);
		this.checkFirmwareVersions = this.checkFirmwareVersions.bind(this);
		this.updateGatewayFirmware = this.updateGatewayFirmware.bind(this);
		this.startFirmwareUpdate = this.startFirmwareUpdate.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleConfirmClose = this.handleConfirmClose.bind(this);
		this.showRebootDialog = this.showRebootDialog.bind(this);
		this.rebootGateway = this.rebootGateway.bind(this);
		this.setHost = this.setHost.bind(this);
		this.handleSelectHost = this.handleSelectHost.bind(this);
		this.updateGagent2 = this.updateGagent2.bind(this);
		this.updateGagentLib = this.updateGagentLib.bind(this);
		this.handleOpenDoctorClick = this.handleOpenDoctorClick.bind(this);
	}

	componentDidMount() {
		Gupport.on("ready", this.handleGupportReady);
		Gupport.on("message", this.handleGupportMessage);

		if (Settings.loaded) {
			this.handleSettingsLoaded();
		} else {
			Settings.once("loaded", this.handleSettingsLoaded);
		}

		this.fetchChannels();

		this.fetchGateway(this.props.gatewayId);
	}

	componentWillUnmount() {
		Gupport.off("ready", this.handleGupportReady);
		Gupport.off("message", this.handleGupportMessage);
		Settings.off("loaded", this.handleSettingsLoaded);
	}

	static sortFunc(a, b) {
		return sortAlphabetically(a.name, b.name);
	}

	handleGupportReady() {
		// this.setState({
		// 	ready: Gupport.ready
		// });
	}

	handleGupportMessage(msg) {
		if (msg.payload?.info === "updatePlugins") {
			if (msg.payload.status === "ok") {
				const data = msg.payload.data.sort(GatewayAdvancedInfo.sortFunc);
				this.setState({
					updatingFirmware: false,
					isUpdateFailed: false,
					isFirmwareUpdateCompleted: true,
					updatedFirmwareInfo: data
				});
			} else {
				this.setState({
					updatingFirmware: false,
					isUpdateFailed: true,
					isFirmwareUpdateCompleted: true
				});
			}
		}
	}

	handleSettingsLoaded() {
		this.setState({
			hostValues: Settings.clusters
		});
	}

	fetchGateway(gatewayId) {
		if (Gupport.ready) {
			this.getGateway(gatewayId);
		} else {
			Gupport.once("ready", () => {
				this.getGateway(gatewayId);
			});
		}
	}

	getGateway(gatewayId) {
		Gupport.getGateway({
			gatewayId: gatewayId
		}, (error, msg) => {
			if (error || msg.payload.status === "error") {
				this.setState({
					loading: false,
					searchError: error || msg.payload.data,
					gateway: null
				});
			} else {
				this.setState({
					loading: false,
					searchError: error,
					gateway: msg.payload.data
				});
			}
		});
	}

	handleClose() {
		this.setState({
			openDialog: false,
			rebootDialog: false,
			showUpdateFirmwareDialog: false,
			showFirmwareCheckErrorDialog: false
		});
	}

	handleConfirmClose() {
		this.setState({
			showConfirmUpdateDialog: false
		});
	}

	getChannels() {
		Gupport.getTable({
			tableName: "channels"
		}, (error, msg) => {
			if (msg.payload.status === "ok") {
				const channels = msg.payload.data.map((data) => (data.id.string_key));
				this.setState({
					channelValues: channels
				});
			}
		});
	}

	fetchChannels() {
		if (Gupport.ready) {
			this.getChannels();
		} else {
			Gupport.once("ready", () => {
				this.getChannels();
			});
		}
	}

	handleSelectChannel(event) {
		this.setState((prevState) => ({
			gateway: merge(prevState.gateway, { channel: event.target.value }),
			buttonDisable: false
		}));
	}

	handleSelectHost(event) {
		this.setState((prevState) => ({
			gateway: merge(prevState.gateway, { host: event.target.value }),
			hostButton: false
		}));
	}

	setChannel() {
		const { t } = this.props;
		this.setState({
			savingChannel: true,
			buttonDisable: true
		});
		Gupport.setChannel({
			channel: this.state.gateway.channel,
			gatewayId: this.state.gateway.id
		}, (error, msg) => {
			if (!error && msg.payload.status === "ok") {
				this.setState({
					savingChannel: false,
					buttonDisable: false,
					channelEditMode: false
				});
			} else {
				this.setState({
					openDialog: true,
					action: t("gateway.channelError"),
					savingChannel: false,
					buttonDisable: false,
					channelEditMode: false
				});
			}
		});
	}

	checkFirmwareVersions() {
		this.setState({
			checkingFirmwareVersions: true,
			isUpdateFailed: false,
			isFirmwareUpdateCompleted: false
		});

		const cmd = {
			action: "gatewayAction",
			module: "gateway",
			function: "get_plugins",
			params: [],
			gatewayId: this.state.gateway.id
		};
		Gupport.send(cmd, (error, msg) => {
			if (!error && msg.payload.status === "ok") {
				const data = msg.payload.data;
				data.running.sort(GatewayAdvancedInfo.sortFunc);
				data.config.sort(GatewayAdvancedInfo.sortFunc);
				this.setState({
					showUpdateBtn: msg.payload.data.running && msg.payload.data.running.length > 0,
					checkingFirmwareVersions: false,
					showUpdateFirmwareDialog: true,
					firmware: data
				});
			} else {
				this.setState({
					checkingFirmwareVersions: false,
					showFirmwareCheckErrorDialog: true
				});
			}
		});
	}

	updateGatewayFirmware() {
		this.setState({
			showConfirmUpdateDialog: true
		});
	}

	startFirmwareUpdate() {
		this.setState({
			showUpdateBtn: false,
			showConfirmUpdateDialog: false
		});

		const cmd = {
			action: "gatewayAction",
			module: "gateway",
			function: "update_plugins",
			params: [],
			gatewayId: this.state.gateway.id
		};
		Gupport.send(cmd, (error, msg) => {
			if (!error && msg.payload.status === "ok" && msg.payload.data === "started") {
				this.setState({
					updatingFirmware: true
				});
			} else {
				this.setState({
					updatingFirmware: false,
					isUpdateFailed: true,
					isFirmwareUpdateCompleted: true
				});
			}
		});
	}

	setHost() {
		const { t } = this.props;
		this.setState({
			savingHost: true,
			hostButton: true
		});
		Gupport.setGerver({
			gerver: this.state.gateway.host,
			gatewayId: this.state.gateway.id
		}, (error, msg) => {
			if (!error && msg.payload.status === "ok") {
				this.setState({
					savingHost: false,
					hostButton: false,
					hostEditMode: false
				});
			} else {
				this.setState({
					openDialog: true,
					action: t("gateway.hostError"),
					savingHost: false,
					hostButton: false,
					hostEditMode: false
				});
			}
		});
	}

	rebootGateway() {
		const { t } = this.props;
		this.setState({
			rebootButtonDisabled: true
		});
		Gupport.rebootGateway({
			gatewayId: this.state.gateway.id
		}, (error, msg) => {
			if (msg.payload.status === "ok") {
				this.handleClose();
			} else {
				this.setState({
					openDialog: true,
					rebootDialog: false,
					action: t("gateway.rebootError")
				});
			}
		});
	}

	showRebootDialog() {
		this.setState({
			rebootDialog: true,
			rebootButtonDisabled: false
		});
	}

	updateGagent2() {
		const { t } = this.props;
		// this.setState({
		// 	disableUpdateGagent2: true,
		// 	updatingGagent: true
		// });

		const cmd = {
			action: "gatewayAction",
			module: "gateway",
			function: "update_beamster",
			params: {},
			gatewayId: this.state.gateway.id
		};
		Gupport.send(cmd, (error, msg) => {
			if (msg.payload.status === "ok") {
				this.handleClose();
				// this.setState({
				// 	disableUpdateGagent2: false,
				// 	updatingGagent: false
				// });
			} else {
				this.setState({
					openDialog: true,
					action: t("gateway.updateGagent2Error")
					// disableUpdateGagent2: false,
					// updatingGagent: false
				});
			}
		});
	}

	updateGagentLib() {
		const { t } = this.props;
		// this.setState({
		// 	disableUpdateGagent2Lib: true,
		// 	updatingGagentLib: true
		// });

		const cmd = {
			action: "gatewayAction",
			module: "gateway",
			function: "update_modules",
			params: {},
			gatewayId: this.state.gateway.id
		};
		Gupport.send(cmd, (error, msg) => {
			if (msg.payload.status === "ok") {
				this.handleClose();
				// this.setState({
				// 	disableUpdateGagent2Lib: false,
				// 	updatingGagentLib: false
				// });
			} else {
				this.setState({
					openDialog: true,
					action: t("gateway.updateGagentLibError")
					// disableUpdateGagent2Lib: false,
					// updatingGagentLib: false
				});
			}
		});
	}

	setEditMode(name) {
		switch (name) {
			case "channel":
				this.setState({
					channelEditMode: true
				});
				break;
			case "host":
				this.setState({
					hostEditMode: true
				});
				break;
		}
	}

	getUpdateDialogContent() {
		const { t } = this.props;
		if (!this.state.showUpdateFirmwareDialog) {
			return null;
		}
		if (this.state.isUpdateFailed) {
			return <DialogContentText>{t("gateway.firmwareUpdateFailure")}</DialogContentText>;
		}
		if (this.state.updatingFirmware) {
			return (
				<div className="loader">
					<CircularProgress />
					<span>{t("gateway.updating")}</span>
				</div>
			);
		}
		return this.state.isFirmwareUpdateCompleted
			? <UpdatedFirmwareInfo updatedFirmwareInfo={this.state.updatedFirmwareInfo} />
			: <UpdateFirmwareInfo firmware={this.state.firmware} />;
	}

	handleOpenDoctorClick(event) {
		this.setState({
			value: event.target.value,
			actionLoading: true,
		});
		const cmd = {
			action: "cloudAction",
			module: "gupport",
			function: "open_doctor",
			params: {
				id: this.props.gatewayId,
				port: this.state.value,
			},
		};
		Gupport.send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				this.setState({
					actionLoading: false,
					actionResultPayload: error ? undefined : msg.payload.data,
					actionResultError: error,
				});
			} else {
				this.setState({
					actionResultError: error,
				});
			}
		});
	}

	handleCopyClick(data) {
		const json = JSON.stringify(data, null, 2);
		navigator.clipboard.writeText(json).then(() => {
			this.setState({
				showCopySnackbar: true,
			});
		}, (error) => {
			console.warn(error);
		});
	}

	render() {
		const { t } = this.props;
		const hasWriteAccess = Gupport.user && Gupport.user.table_write && Gupport.user.table_write.includes("rocid_dict");
		const isPortInRange = this.state.value && this.state.value > 2221 && this.state.value < 2230;

		if (this.state.loading) {
			return <CircularProgress />;
		}
		if (this.state.searchError) {
			return <div>{this.state.searchError}</div>;
		}
		// const isUpdateBeamsterAvail = true; //Boolean(Gupport.updateBeamster);
		// const isUpdateModulesAvail = true; //Boolean(Gupport.updateModules);
		const isRebootGatewayAvail = Boolean(Gupport.rebootGateway);

		let upDownTimeTitle = t("gateway.upTime");
		let upDownTimeValue = "";

		if (this.state.gateway) {
			const connected = this.state.gateway.connected
				? new Date(Number.isInteger(this.state.gateway.connected) ? this.state.gateway.connected / 1000 : this.state.gateway.connected)
				: null;
			const disconnected = this.state.gateway.disconnected
				? new Date(Number.isInteger(this.state.gateway.disconnected) ? this.state.gateway.disconnected / 1000 : this.state.gateway.disconnected)
				: null;
			if (connected && disconnected) {
				let time;
				if (connected > disconnected) {
					time = intervalToDuration({ start: new Date(), end: connected });
				} else {
					upDownTimeTitle = t("gateway.downTime");
					time = intervalToDuration({ start: new Date(), end: disconnected });
				}
				if (time.years > 0) {
					upDownTimeValue += `${time.years} Y `;
				}
				if (time.months > 0) {
					upDownTimeValue += `${time.months} M `;
				}
				if (time.days > 0) {
					upDownTimeValue += `${time.days} D `;
				}
				upDownTimeValue += `${time.hours} H ${time.minutes} m ${time.seconds} s`;
			}
			const sgateway = this.state.gateway;
			sgateway.videoStorage = this.state.gateway.videoStorage || {};
		}

		return (
			<>
				<Paper component="section" className="card-separator card-info">
					<RowEntry title={t("gateway.tableChannel")} styleRight={{ margin: "-14px 0" }}>
						{Gupport.setChannel ?
							<>
								<Select
									name="channel"
									value={this.state.gateway.channel}
									disabled={!this.state.channelEditMode}
									onChange={this.handleSelectChannel}
									style={{ width: "250px" }}
								>
									{
										this.state.channelValues.map((token) => (
											<MenuItem key={token} value={token}>
												<ListItemText primary={token} />
											</MenuItem>
										))
									}
								</Select>
								{this.state.channelEditMode ?
									<LoadingButton
										disabled={this.state.buttonDisable}
										loading={this.state.savingChannel}
										onClick={this.setChannel}
										style={{ marginTop: 4 }}
									>
										{t("messages.save")}
									</LoadingButton>
									:
									<Button style={{ marginTop: 4 }} onClick={this.setEditMode.bind(this, "channel")}>
										{t("messages.edit")}
									</Button>
								}
							</>
							: this.state.gateway.channel
						}
					</RowEntry>
					<Divider />
					<RowEntry title={t("gateway.host")} styleRight={{ margin: "-14px 0" }}>
						{Gupport.setGerver ?
							<>
								<Select
									name="channel"
									value={this.state.gateway.host}
									disabled={!this.state.hostEditMode}
									onChange={this.handleSelectHost}
									style={{ width: "250px" }}
								>
									{
										this.state.hostValues.map((host) => (
											<MenuItem key={host.id} value={host.id}>
												<ListItemText primary={host.id} />
											</MenuItem>
										))
									}
								</Select>
								{this.state.hostEditMode ?
									<LoadingButton
										disabled={this.state.hostButton}
										loading={this.state.savingHost}
										onClick={this.setHost}
										style={{ marginTop: 4 }}
									>
										{t("messages.save")}
									</LoadingButton>
									:
									<Button onClick={this.setEditMode.bind(this, "host")} style={{ marginTop: 4 }}>
										{t("messages.edit")}
									</Button>
								}
							</>
							: <ListItemText primary={this.state.gateway.host} />
						}
					</RowEntry>
					<Divider />
					<RowEntry title={t("gateway.ggantTime")}>
						{
							this.state.gateway.gagent_ts
								? `${this.state.gateway.gagent_ts} (${new Date(this.state.gateway.gagent_ts).toLocaleString()})`
								: <Na />
						}
					</RowEntry>
					<Divider />
					<RowEntry title={t("gateway.socketStatus")}>
						{this.state.gateway.socket_status || <Na />}
					</RowEntry>
					<Divider />
					<RowEntry title={upDownTimeTitle}>
						{upDownTimeValue || <Na />}
					</RowEntry>
					<Divider />
					<RowEntry title={t("gateway.lastPing")}>
						{
							this.state.gateway.lastPing
								? `${new Date(this.state.gateway.lastPing).toLocaleString()} ${t("gateway.over")}`
								: <Na />
						}
						<strong> {this.state.gateway.over}</strong>
					</RowEntry>
					<Divider />
					{/*<RowEntry title={t("gateway.agent")}>
						{this.state.gateway.agent || <Na />}
					</RowEntry>
					<Divider />
					*/}
					<RowEntry title={t("gateway.ip")}>
						{this.state.gateway.ip || <Na />}
					</RowEntry>
					<Divider />
					<RowEntry title={t("gateway.node")}>
						{this.state.gateway.node || <Na />}
					</RowEntry>
				</Paper>
				<section>
					<h4 className="sub-section-header">{t("gateway.firmWare")}</h4>
					<Paper className="card-separator card-info">
						<RowEntry title={t("gateway.branch")} styleRight={{ margin: "-12px 0" }}>
							{this.state.gateway.branch || <Na />}
							<LoadingButton
								loading={this.state.checkingFirmwareVersions}
								onClick={this.checkFirmwareVersions}
							>
								{t("gateway.checkFirmwareVersions")}
							</LoadingButton>
						</RowEntry>
						<Divider />
						<RowEntry title={t("gateway.group")}>
							{this.state.gateway.group || <Na />}
						</RowEntry>
						<Divider />
						<RowEntry title={t("gateway.buildDate")}>
							{this.state.gateway.buildDate || <Na />}
						</RowEntry>
						<Divider />
						<RowEntry title={t("gateway.build")}>
							{this.state.gateway.buildId || <Na />}
						</RowEntry>
						{/*{(isUpdateBeamsterAvail || isUpdateModulesAvail) &&
							<>
								<Divider />
								<RowEntry title={t("gateway.update")}>
									{isUpdateBeamsterAvail && <span>
										<LoadingButton
											variant="contained"
											disabled={this.state.disableUpdateGagent2}
											loading={this.state.updatingGagent}
											onClick={this.updateGagent2}
										>
											{t("gateway.updateGagent2")}
										</LoadingButton>
										</span>}
										{isUpdateModulesAvail && <span>
											<LoadingButton
											variant="contained"
											disabled={this.state.disableUpdateGagent2Lib}
											loading={this.state.updatingGagentLib}
											onClick={this.updateGagentLib}
										>
											{t("gateway.updateGagentLib")}
										</LoadingButton>
										</span>}
								</RowEntry>
							</>
						}*/}
						{isRebootGatewayAvail &&
							<>
								<Divider />
								<RowEntry title={t("gateway.systemReboot")} styleRight={{ margin: "-12px 0" }}>
									<Button onClick={this.showRebootDialog} style={{ margin: "4px -8px" }}>
										{t("gateway.reboot")}
									</Button>
								</RowEntry>
							</>
						}
						<Divider />
						{hasWriteAccess &&
							<>
								<RowEntry title={t("gateway.openDoctor")}>
									<TextField
										placeholder={t("gateway.enterPort")}
										type="number"
										value={this.state.value}
										sx={{ width: "40%" }}
										onChange={(event) => this.setState({ value: event?.target.value })}
										inputProps={{
											min: 2222,
											max: 2229,
											inputMode: "numeric",
										}}
										helperText={isPortInRange ? undefined : t("gateway.enterPortHelperText")}
										FormHelperTextProps={{ sx: { margin: "0", textAlign: "right" } }}
									/>
									<Button disabled={!isPortInRange} onClick={this.handleOpenDoctorClick}>{t("gateway.openDoctor")}</Button>
								</RowEntry>
								<div style={{ marginLeft: "30%"}}>
									<ActionResult
										loading={this.state.actionLoading}
										error={this.state.actionResultError}
										payload={this.state.actionResultPayload}
									/>
									{!this.state.actionLoading && this.state.actionResultPayload &&
										<Button onClick={() => (this.handleCopyClick(this.state.actionResultPayload))}>
											{t("gateway.copy")}
										</Button>
									}
								</div>
							</>
						}
					</Paper>
				</section>
				{/*
				<section>
					<h4 className="sub-section-header">{t("gateway.videoStorage")}</h4>
					<Paper style={{padding: "2px"}} className="card-separator">
						<Table>
							<TableBody>
								<TableRow>
									<TableCell style={columnStyle}>{t("gateway.vEndTime")}</TableCell>
									<TableCell>
										{this.state.gateway.videoStorage.endTime
											? new Date(this.state.gateway.videoStorage.endTime).toLocaleString()
											: <Na />
										}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell style={columnStyle}>{t("gateway.sHost")}</TableCell>
									<TableCell>
										{this.state.gateway.videoStorage.storageHost || <Na />}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell style={columnStyle}>{t("gateway.sContainer")}</TableCell>
									<TableCell>
										{this.state.gateway.videoStorage.storageContainer || <Na />}
									</TableCell>
								</TableRow>
								<TableRow className="last-row-no-border">
									<TableCell style={columnStyle}>{t("gateway.sType")}</TableCell>
									<TableCell>
										{this.state.gateway.videoStorage.type || <Na />}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</Paper>
				</section>
				*/}
				{/*
				<section>
					<h4 className="sub-section-header">{t("gateway.bMessages")}</h4>
					<Paper style={{padding: "2px", marginBottom: "2px"}} className="card-separator">
						<Table>
							<TableBody>
								<TableRow>
									<TableCell style={columnStyle}>{t("gateway.rxSize")}</TableCell>
									<TableCell>
										{formatBytes(this.state.gateway.rxSize) || <Na />}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell style={columnStyle}>{t("gateway.rxCount")}</TableCell>
									<TableCell>
										{this.state.gateway.rxCount === undefined ?
											<Na />:
											this.state.gateway.rxCount
										}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell style={columnStyle}>{t("gateway.txSize")}</TableCell>
									<TableCell>
										{formatBytes(this.state.gateway.txSize) || <Na />}
									</TableCell>
								</TableRow>
								<TableRow className="last-row-no-border">
									<TableCell style={columnStyle}>{t("gateway.txCount")}</TableCell>
									<TableCell>
										{this.state.gateway.txCount === undefined ?
											<Na />:
											this.state.gateway.txCount
										}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</Paper>
				<section>
				*/}
				<Dialog
					fullWidth={true}
					open={this.state.openDialog}
					onClose={this.handleClose}
				>
					<DialogTitle>{t("dialog.error")}</DialogTitle>
					<DialogContent>
						<DialogContentText>
							{this.state.action}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose}>{t("dialog.ok")}</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					fullWidth={true}
					open={this.state.rebootDialog}
					onClose={this.handleClose}
				>
					<DialogTitle>{t("gateway.rebootTitle")}</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<Trans
								i18nKey="gateway.rebootMessage"
								values={{ gatewayName: this.state.gateway.name }}
							/>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<LoadingButton
							loading={this.state.rebootButtonDisabled}
							onClick={this.rebootGateway}
						>
							{t("gateway.reboot")}
						</LoadingButton>
						<Button color="inherit" onClick={this.handleClose}>{t("dialog.cancel")}</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					fullWidth={true}
					maxWidth={this.state.isUpdateFailed ? "sm" : "lg"}
					open={this.state.showUpdateFirmwareDialog}
					onClose={this.handleClose}
				>
					<DialogTitle>{t("gateway.updateFirmwareTitle")}</DialogTitle>
					<DialogContent style={this.state.isUpdateFailed ? {} : { minHeight: "300px", padding: 0 }}>
						{this.getUpdateDialogContent()}
					</DialogContent>
					<DialogActions>
						{this.state.showUpdateBtn && <Button onClick={this.updateGatewayFirmware}>{t("gateway.updateGateway")}</Button>}
						<Button color="inherit" onClick={this.handleClose}>{t("dialog.close")}</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					fullWidth={true}
					open={this.state.showConfirmUpdateDialog}
					onClose={this.handleClose}
				>
					<DialogTitle>{t("gateway.updateFirmwareTitle")}</DialogTitle>
					<DialogContent>
						<DialogContentText>
							{reactStringReplace(t("gateway.confirmUpdateFirmwareMsg", { GATEWAY_NAME: this.state.gateway.name || this.state.gateway.id }), BBCODE_BOLD_REGEX, (match, index) => (
								<strong key={index}>{match}</strong>
							))}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<LoadingButton
							loading={this.state.updatingFirmware}
							onClick={this.startFirmwareUpdate}
						>
							{t("gateway.startUpdate")}
						</LoadingButton>
						<Button color="inherit" onClick={this.handleConfirmClose}>{t("dialog.close")}</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					fullWidth={true}
					open={this.state.showFirmwareCheckErrorDialog}
					onClose={this.handleClose}
				>
					<DialogTitle>{t("gateway.checkFirmwareErrorTitle")}</DialogTitle>
					<DialogContent>
						<DialogContentText>
							{reactStringReplace(t("gateway.checkFirmwareErrorMsg", { GATEWAY_NAME: this.state.gateway.name || this.state.gateway.id }), BBCODE_BOLD_REGEX, (match, index) => (
								<strong key={index}>{match}</strong>
							))}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose}>{t("dialog.ok")}</Button>
					</DialogActions>
				</Dialog>
				<Snackbar
					open={this.state.showCopySnackbar}
					message={t("gateway.copyMsg")}
					autoHideDuration={3000}
					onClose={() => this.setState({ showCopySnackbar: false })}
				/>
			</>
		);
	}

}

GatewayAdvancedInfo.propTypes = {
	gatewayId: PropTypes.string.isRequired,
	t: PropTypes.func.isRequired,
};

export default withTranslation()(GatewayAdvancedInfo);
